/* eslint-disable max-lines */
import { memo, useState } from 'react';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useRouter } from 'next/router';
import { nanoid } from 'nanoid';
import { BUILD_FLOW_STEPS, LARGE_WIDTH, MEDIUM_MAX_WIDTH, MEDIUM_WIDTH } from '@constants';
import { useIsMobile, useTranslation } from '@utils/hooks';
import { useBFContext } from '@context';
import { Bag, Caption, CartNavButton, Close, Drawer, Flex, Heading, LabelText, Modal, MyTops, FilterButton } from '@components';
import { useCart } from '@services/shopify';
import { BUNDLE_KEY_QUERY } from '@utils/constants/cart';
import variables from '@styles/export.module.scss';
import styles from './ProductViewAllTops.module.scss';

const TopFrameEducation = dynamic(() => import('@components').then(mod => mod.TopFrameEducation));
const CartActions = dynamic(() => import('@components').then(mod => mod.CartActions));
const StepTracker = dynamic(() => import('@components').then(mod => mod.StepTracker));
const Microcart = dynamic(() => import('@components').then(mod => mod.MicrocartQuickAdd), {
	loading: () => <CartNavButton href='/cart' />,
});

const getTranslatedTexts = translator => {
	return {
		chooseYourTop: translator('choose-your-top'),
		chooseYourTops: translator('choose-your-tops'),
		myTopFrames: translator('my-top-frames'),
		closeUppercase: translator('close-uppercase'),
		completeYourPair: translator('complete-your-pair'),
	};
};

const ProductViewAllTops = ({ showMobile }: { showMobile: boolean }) => {
	const isV2Subscription = useFeatureIsOn('is-v2-subscription-test');
	const isBF2AllTops = useFeatureIsOn('bf-2-all-tops');
	const isFilterOutline = useFeatureIsOn('is-filter-outline-button');
	const isAlwaysOpen = useFeatureIsOn('is-always-filter-tops');
	const isChooseTopsHover = useFeatureIsOn('is-choose-tops-hover');
	const { tops, step, handleTop, isSubscriptionActivated, skipSubscription, isQuickAdd, chooseTopsFilter } = useBFContext();
	const isMobile = useIsMobile({ maxWidth: MEDIUM_WIDTH });
	const isMobileTablet = useIsMobile({ maxWidth: MEDIUM_MAX_WIDTH });
	const isMobileLarge = useIsMobile({ maxWidth: LARGE_WIDTH });
	const hasTops = Object.keys(tops).length !== 0;
	const [modalOpen, setModalOpen] = useState(false);
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const { data: cart } = useCart();
	const { query } = useRouter();
	const savedBundle = cart?.bundles && query[BUNDLE_KEY_QUERY] ? cart?.bundles[`${query[BUNDLE_KEY_QUERY]}`] : null;
	const baseFrameQuickAdd = savedBundle?.base?.frame;
	const enableFilter = isFilterOutline || isAlwaysOpen || isChooseTopsHover;

	const showProductViewActions = !isSubscriptionActivated || (isSubscriptionActivated && skipSubscription) || isV2Subscription;

	function handleModalChange(shouldOpen: boolean) {
		setModalOpen(shouldOpen);
	}

	const topFrameStep =
		step === BUILD_FLOW_STEPS.TOP_FRAMES || (!(isBF2AllTops && !isSubscriptionActivated) && step === BUILD_FLOW_STEPS.SUBMIT);
	const titleProductView = !enableFilter ? translations.chooseYourTop : translations.chooseYourTops;

	const triggerButton = (
		<Flex className={styles['shopping-bag-container']}>
			<Flex
				className={cn(styles['shopping-bag-trigger'], {
					[styles['is-active']]: hasTops,
					[styles['shopping-bag-trigger--is-filter-outline']]: enableFilter,
				})}
				align='center'
				justify='center'
			>
				<Bag />
			</Flex>
			<Flex
				align='center'
				justify='center'
				className={cn(styles['mobile-count'], {
					[styles['is-active']]: hasTops,
					[styles['mobile-count--is-filter-outline']]: (isFilterOutline || isAlwaysOpen) && !isMobileTablet,
					[styles['mobile-count--is-filter-outline-quick-add']]: (isFilterOutline || isAlwaysOpen) && isQuickAdd,
				})}
			>
				<Caption style={{ fontWeight: 700, fontSize: '1.4rem', position: 'relative' }}>
					{isQuickAdd ? (baseFrameQuickAdd ? 1 : 0) + Object.keys(tops).length : Object.keys(tops).length}
				</Caption>
			</Flex>
		</Flex>
	);

	const topFrameContent = (
		<>
			<Flex gap={3} align='center' justify='start' className={styles.header} fullWidth>
				<Flex gap={3} align='center'>
					<Caption>{hasTops ? translations.myTopFrames : translations.completeYourPair}</Caption>
					{hasTops && (
						<Flex
							align='center'
							justify='center'
							className={cn(styles['tops-counter'], { [styles['is-active']]: hasTops })}
						>
							<LabelText style={{ fontWeight: 700, fontSize: '1.4rem' }}>{Object.keys(tops).length}</LabelText>
						</Flex>
					)}
				</Flex>
				{!isMobile && (
					<Drawer.Close asChild>
						<Close wrapperClass={styles['close-button']} height={13} width={13} label={translations.closeUppercase} />
					</Drawer.Close>
				)}
			</Flex>
			<Flex column align='center' fullWidth className={styles.drawerContent}>
				<MyTops tops={tops} onTrashClick={handleTop} />
			</Flex>
		</>
	);
	const heading = (
		<Heading tag='h6' style={{ marginTop: '0' }}>
			{titleProductView}
		</Heading>
	);
	const results = (
		<Caption style={{ color: variables.gray4 }} data-testid='tops-counter' small={isMobileLarge}>
			{chooseTopsFilter?.resultNumber ? chooseTopsFilter.resultNumber : 0} items found
		</Caption>
	);
	const getDesktopContent = () => {
		const renderProductViewActions = showProductViewActions && (
			<Flex column>
				<Flex align='center' justify='center' gap={3}>
					{heading}
					<TopFrameEducation isMobile disableTitle />
				</Flex>
				{(isAlwaysOpen || isFilterOutline) && results}
			</Flex>
		);

		const renderDrawer = isMobile ? (
			<Modal open={modalOpen} onOpenChange={handleModalChange}>
				<Modal.Trigger>{triggerButton}</Modal.Trigger>
				<Modal.Content className={styles.modalContent}>{topFrameContent}</Modal.Content>
			</Modal>
		) : (
			showProductViewActions &&
			!isQuickAdd && (
				<Flex>
					<Drawer>
						<Drawer.Trigger asChild>{triggerButton}</Drawer.Trigger>
						<Drawer.Content blurOverlay={false} grayBackground>
							<div>
								{topFrameContent}
								<div className={styles['bf-drawer-cart-actions']}>
									<CartActions />
								</div>
							</div>
						</Drawer.Content>
					</Drawer>
				</Flex>
			)
		);

		const renderMicrocart = isQuickAdd && <Microcart triggerButton={triggerButton} tops={tops} onTrashClick={handleTop} />;
		if (isChooseTopsHover) {
			return [
				renderProductViewActions,
				<Flex key='step-tracker' fullWidth={!showProductViewActions}>
					<StepTracker />
				</Flex>,
				renderDrawer,
				renderMicrocart,
			];
		}

		if (!(isFilterOutline || isAlwaysOpen)) {
			return [
				showProductViewActions && (
					<Flex column>
						{heading}
						<TopFrameEducation isMobile />
					</Flex>
				),
				<Flex key='step-tracker' fullWidth={!showProductViewActions}>
					<StepTracker />
				</Flex>,
				renderDrawer,
				renderMicrocart,
			];
		}

		return [
			<Flex key='step-tracker' fullWidth={!showProductViewActions}>
				<StepTracker />
			</Flex>,
			<Flex align='center' justify='between' key={nanoid()} fullWidth fullHeight className={styles['isFilter']}>
				{renderProductViewActions}
				<Flex>
					{(!isAlwaysOpen || isMobileLarge) && (
						<FilterButton
							color='blue-outline'
							isFilterOutline
							onClick={chooseTopsFilter?.callback}
							open={!!chooseTopsFilter?.value}
							filtered={!!chooseTopsFilter?.filtered}
						/>
					)}
					{!isQuickAdd && renderDrawer}
					{renderMicrocart}
				</Flex>
			</Flex>,
		];
	};

	const getMobileTitle = () => {
		const button = isQuickAdd ? (
			<Microcart triggerButton={triggerButton} tops={tops} onTrashClick={handleTop} />
		) : (
			<Modal open={modalOpen} onOpenChange={handleModalChange}>
				<Modal.Trigger>{triggerButton}</Modal.Trigger>
				<Modal.Content className={styles.modalContent}>{topFrameContent}</Modal.Content>
			</Modal>
		);
		if (!enableFilter)
			return [
				<Flex fullWidth justify='center' className={styles['preview-header']} key={nanoid()}>
					{heading}
					{button}
				</Flex>,
				<TopFrameEducation isMobile key={nanoid()} />,
			];
		return [
			<Flex fullWidth justify='between' key={nanoid()}>
				<Flex align='center' justify='center' gap={3}>
					{heading}
					<TopFrameEducation isMobile disableTitle key={nanoid()} />
				</Flex>
				{button}
			</Flex>,
		];
	};

	return (
		<Flex
			column
			align='center'
			justify='between'
			className={styles['product-view']}
			data-use-padding={!topFrameStep}
			data-use-height-tops-step={topFrameStep}
		>
			{isMobileTablet ? (
				showProductViewActions &&
				showMobile && (
					<Flex column fullWidth align='center' className={styles['no-preview']}>
						{getMobileTitle()}
						{enableFilter && (
							<FilterButton
								color='blue-outline'
								isFilterOutline
								filtered={!!chooseTopsFilter?.filtered}
								style={{ padding: 0 }}
							/>
						)}
						{(isFilterOutline || isAlwaysOpen) && (
							<Flex align='center' justify='center' fullWidth style={{ paddingBlock: '1rem' }}>
								{results}
							</Flex>
						)}
					</Flex>
				)
			) : (
				<Flex justify='between' fullWidth align='center' column={isFilterOutline || isAlwaysOpen} gap={0}>
					{getDesktopContent()}
				</Flex>
			)}
		</Flex>
	);
};

export default memo(ProductViewAllTops);
